import { storeToRefs } from 'pinia';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import type { Attribute } from '@/composables/attributesRenderer';
import { useFeatureConfig } from '@/composables/featureConfig';
import { useTheme } from '@/composables/theme';
import useGTM from '@/composables/useGTM';
import configApp from '@/config/app.json';
import { useStepsLpAuctionBid } from '@/factories/formStepsFactory/lpAuctionBid';
import { useStepsLpInterest } from '@/factories/formStepsFactory/lpInterest';
import { useStepsLpScheduleTour } from '@/factories/formStepsFactory/lpScheduleTour';
import translationsLP from '@/i18n/translations/components/listingPage.json';
import { useListingStore } from '@/store/modules/listing';
import type { TCan } from '@/types';

export const useVarPool = () => {
  const { locale, t } = useI18n({ ...translationsLP, useScope: 'global' });

  const listingStore = useListingStore();
  const {
    auctionRounds,
    isPreview,
    listing,
    listingConfig,
    listingData,
    listingMetaData,
    newlyListedVisible,
  } = storeToRefs(listingStore);
  const { icons } = useTheme();
  const { featureConfig } = useFeatureConfig();

  const cans = computed(() => {
    if (!listing.value || !listingConfig.value || !listingData.value || !listingMetaData.value) {
      return [];
    }

    const isAcquired = listingData.value.listingInfo.statuses.acquired;
    const isAuction = !!listing.value.auction;

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const _cans: TCan[] = [];

    _cans.push(
      listing.value.commercializationType,
      listing.value.category,
      listing.value.type
      // ...listing.value.listingInfo.labels
    );

    if (
      !!listing.value.extraInformation.sections.length ||
      !!listing.value.extraInformation.units?.length
    ) {
      _cans.push('extra-info');
    }

    if (auctionRounds.value.length) {
      _cans.push('auction-rounds');

      if (listingData.value.listingInfo.auction?.siblings.length) {
        _cans.push('auction-siblings');
      }
    }

    if (
      featureConfig.value.listing.listingDocuments &&
      (listingMetaData.value.hasDocuments || isPreview.value)
    ) {
      _cans.push('documents');
    }

    if (listingConfig.value.locationInfo?.enabled ?? true) {
      _cans.push('location');
    }

    if (
      featureConfig.value.listing.identificationDetails &&
      listingMetaData.value.hasIdentificationDetails
    ) {
      _cans.push('lro');
    }

    if (listingData.value.propertyInfo.units.length > 1) {
      _cans.push('multi-unit');
    }

    if (
      isAuction &&
      !listing.value.auction.auctionDatePassed() &&
      listing.value.auction.nextRoundComingSoon
    ) {
      _cans.push('auction-next-round');
    }

    if (isAuction && listing.value.auction.dateToBeAnnounced) {
      _cans.push('auction-to-be-announced');
    }

    if (listingMetaData.value.priceUponRequest) {
      _cans.push('price-upon-request');
    }

    if (
      featureConfig.value.listing.renovationCostAnalysis &&
      !!listingData.value.propertyInfo?.renovation &&
      (listingConfig.value.renovationCostAnalysis?.enabled ?? true)
    ) {
      _cans.push('renovation');
    }

    if (featureConfig.value.listing.revenues && listing.value.revenues) {
      _cans.push('revenues');
    }

    if (featureConfig.value.listing.runningCosts && listing.value.runningCosts) {
      _cans.push('running-costs');
    }

    if (
      featureConfig.value.listing.similarListings &&
      (listingConfig.value.similarListings?.enabled ?? true)
    ) {
      _cans.push('similar');
    }

    if (_cans.filter(c => ['revenues', 'running-costs'].includes(c)).length) {
      _cans.push('financial');
    }

    if (isAcquired) {
      _cans.push('acquired');
      return _cans;
    }

    // when listing is not acquired...
    if (newlyListedVisible.value) {
      _cans.push('new');
    }

    if (
      configApp.agentInformationVisible &&
      (!isAuction || listing.value.auction.biddingAvailable())
    ) {
      _cans.push('agent');
    }

    if (
      !configApp.agentInformationVisible &&
      (!isAuction || listing.value.auction.biddingAvailable())
    ) {
      _cans.push('help');
    }

    if (featureConfig.value.listing.loanRequest) {
      _cans.push('lp-mortgage');
    }

    if (isAuction && !_cans.includes('auction-next-round')) {
      return _cans;
    }

    if (
      featureConfig.value.listing.auctionBid &&
      isAuction &&
      listing.value.auction.biddingAvailable()
    ) {
      _cans.push('lp-auction-bid');
    }

    if (featureConfig.value.listing.infoRequest) {
      _cans.push('lp-interest');
    }

    return _cans;
  });

  const ownershipData = computed(
    () => (listing.value?.ownership.fullOwnership === false && listing.value.ownership) || null
  );

  const ownershipText = computed(() => {
    if (
      !ownershipData.value ||
      !ownershipData.value.coOwnershipShare ||
      !ownershipData.value.coOwnershipRight
    )
      return '';

    return `${ownershipData.value.coOwnershipShare}% ${t(`section.badges.${ownershipData.value.coOwnershipRight}`)}`;
  });
  const badges = computed<Attribute[]>(() => [
    {
      icon: icons.exclusive,
      key: 'exclusive',
      value: '',
      visible: listingStore.hasLabel('exclusive'),
    },
    {
      icon: icons.new,
      key: 'new',
      value: t('section.badges.newlyListed'),
      visible: cans.value.includes('new'),
    },
    {
      icon: icons.hot,
      key: 'hot',
      value: t('section.badges.hot'),
      visible: listingStore.hasLabel('hot') && !cans.value.includes('acquired'),
    },
    {
      icon: icons.underDevelopment,
      key: 'under-development',
      value: t('section.badges.underConstruction'),
      visible: !!listing.value?.isUnderConstruction,
    },
    {
      icon: icons.ownership,
      key: 'ownership-data',
      value: ownershipText.value,
      visible: !!ownershipText.value,
    },
    {
      icon: icons.legallyChecked,
      key: 'legally-checked',
      value: t('section.badges.legallyChecked'),
      visible: listingStore.hasLabel('legallyChecked'),
    },
    {
      icon: icons.technicallyChecked,
      key: 'technically-checked',
      value: t('section.badges.technicallyChecked'),
      visible: listingStore.hasLabel('technicallyChecked'),
    },
  ]);

  const { factoryId: factoryIdAuctionBid, getFormStepsLpAuctionBid } = useStepsLpAuctionBid();
  const { factoryId: factoryIdLpInterest, getFormStepsLpInterest } = useStepsLpInterest();

  useStepsLpScheduleTour();

  const lpAuctionBidSteps = getFormStepsLpAuctionBid();
  const lpInterestSteps = getFormStepsLpInterest();

  const formWidgets = computed(() => [
    {
      props: { factoryId: factoryIdLpInterest, flat: true, steps: lpInterestSteps.value },
      label: t('section.formWidget.label.auctionInfo'),
      name: 'contact',
      teleportTarget: '.layout-main-footer__body',
      visible: cans.value.includes('lp-interest'),
    },
    {
      props: { factoryId: factoryIdAuctionBid, flat: true, steps: lpAuctionBidSteps.value },
      label: t('section.formWidget.label.auctionBid'),
      name: 'auction_bid',
      teleportTarget: '.layout-main-footer__body',
      visible: cans.value.includes('lp-auction-bid'),
    },
  ]);

  const bootPage = () => {
    listingStore.loadCss();

    watch(
      () => cans.value.length,
      v => {
        if (!v) return;

        listingStore.hydrateLro();
      }
    );

    watch(listing, v => {
      if (!v) return;

      useGTM('visit_property_page_listing_id', {
        item_id: v.id,
        item_price: v.price,
        item_type: v.type,
        item_category: v.category,
        item_com_type: 'sale',
      });
    });

    watch(
      locale,
      () => {
        listingStore.loadListing();
      },
      { immediate: true }
    );
  };

  return { badges, bootPage, cans, configApp, formWidgets, icons, t };
};
