import { Screen } from 'quasar';
import { computed, toRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import type { Attribute } from '@/composables/attributesRenderer';
import { type SearchResultsItemProps, useSearchResultsItem } from '@/composables/searchResultsItem';
import { useTheme } from '@/composables/theme';
import translationsListingBadges from '@/i18n/translations/components/listingBadges.json';
import { toThousands } from '@/utils/number';

export const useVarPool = (_props: SearchResultsItemProps) => {
  const props = toRef(_props);

  const searchResultsItem = useSearchResultsItem(props.value as SearchResultsItemProps);

  const tListingBadges = useI18n(translationsListingBadges).t;
  const theme = useTheme();
  const tGlobal = useI18n().t;

  const aggregates = computed<Attribute[]>(() => [
    {
      icon: theme.icons.size,
      key: 'size',
      value: `${toThousands(props.value.listing.size)} ${tGlobal('sqm')}`,
      visible: !!props.value.listing.size,
    },
    {
      icon: theme.icons.floor,
      key: 'floor',
      value: searchResultsItem.getFloorsText(true),
      visible: !!searchResultsItem.getFloorsText(true),
    },
    {
      icon: theme.icons.bedrooms,
      key: 'bedrooms',
      value: searchResultsItem.bedrooms,
      visible: !!searchResultsItem.bedrooms,
    },
    {
      icon: theme.icons.parking,
      key: 'parking',
      value: searchResultsItem.parkingSpaces,
      visible: !!searchResultsItem.parkingSpaces,
    },
    {
      icon: theme.icons.hotelRooms,
      key: 'hotelRooms',
      value: searchResultsItem.hotelRooms.value,
      visible: !!searchResultsItem.hotelRooms.value,
    },
  ]);

  const badges = computed<Attribute[]>(() => [
    {
      icon: theme.icons.exclusive,
      key: 'exclusive',
      value: tListingBadges('exclusive'),
      visible: !!props.value.listing.listingInfo?.exclusive,
    },
    {
      icon: theme.icons.new,
      key: 'new',
      value: tListingBadges('newlyListed'),
      visible: searchResultsItem.newlyListedVisible,
    },
    {
      icon: theme.icons.hot,
      key: 'hot',
      value: tListingBadges('hot'),
      visible: searchResultsItem.listingHasLabel('hot'),
    },
    {
      icon: theme.icons.underDevelopment,
      key: 'under-development',
      value: tListingBadges('underConstruction'),
      visible: props.value.listing.isUnderConstruction,
    },
    {
      icon: theme.icons.ownership,
      key: 'ownership-data',
      visible: searchResultsItem.ownershipTextVisible.value,
      value: searchResultsItem.ownershipText.value,
    },
  ]);

  const screenLtMdOrMap = computed(
    () => Screen.lt.md || searchResultsItem.elListingItemHasClass('listing-item--map')
  );

  watch(
    searchResultsItem.elListingItemVipBadgeBoundingsWidth,
    v => {
      document.documentElement.style.setProperty('--badge-vip-width', v);
    },
    { immediate: true }
  );

  watch(
    searchResultsItem.elListingItemFavoritesBtnWidth,
    v => {
      document.documentElement.style.setProperty('--favorites-btn-width', v);
    },
    { immediate: true }
  );

  return {
    ...searchResultsItem,
    aggregates,
    badges,
    screenLtMdOrMap,
    theme,
  };
};
